<!--
 * @Author: lbh
 * @Date: 2022-06-21 14:51:49
 * @LastEditors: lbh
 * @LastEditTime: 2022-06-21 14:57:15
 * @Description: file content
-->
<template>
  <div class="picture_and_text-box px-picture_and_text-box">
    <!-- 背景 -->
    <img
      v-if="configs.bgSrc"
      class="bg"
      :src="configs.bgSrc"
    />
    <div class="center"></div>
  </div>
</template>

<script>
export default {
  name: "self-pictureAndText",
  props: {
    configs: {
      default () {
        return {
          bgSrc: "",
          title: ""
        }
      }
    },
    Xindex: {
      default () {
        return '';
      }
    }
  },
  methods: {
    clickItem (e) {
      this.$emit('clickItem', e)
    }
  }
}
</script>

<style lang="less" scoped>
</style>