import { render, staticRenderFns } from "./pictureAndText.vue?vue&type=template&id=02470dcd&scoped=true"
import script from "./pictureAndText.vue?vue&type=script&lang=js"
export * from "./pictureAndText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02470dcd",
  null
  
)

export default component.exports